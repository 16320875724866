"use client";

import { Container, Stack } from "@chakra-ui/react";

export default function AuthContainer({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <Container maxW="md" py={{ base: "12", md: "24" }}>
      <Stack spacing="8">{children}</Stack>
    </Container>
  );
}
